import { find, get } from "lodash";
import swal from "sweetalert";
import ApiService from "./ApiService";
import { store } from "../store/store";
import {
  setCycleAlignDescription,
  setHomeIntroductionsList,
  setHomePageBanner,
  setSymptomsCheckerList,
} from "../store/appStore/home";
import PeriodService from "./PeriodService";
import { setFaqCategories } from "../store/appStore/faq";
import { setFastingBannerInfo } from "../store/appStore/fasting";
import dailyNotificationMessages from "../constants/dailyNotificationMessages";
class HomeService {
  async onHomePageReady() {
    try {
      Promise.all([
        this.getHomePageBanners(),
        PeriodService.getPeriodDetail(),
        // .then(this.showPeriodDayNotification)
        this.getCycleAlignDescription(),
        this.setFAQCategories(),
        this.setFastingBanner(),
        this.getSymptoms(),
        this.getHomePageIntroduction(),
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  showPeriodDayNotification(periodDetails) {
    const {
      currentPhaseInfo: { currentPhase, daysCountAfterPhaseStartDate },
      periodPhases,
    } = periodDetails; // daysCountAfterPhaseStartDate starts with 0
    const phaseTitle = find(periodPhases, { phaseCode: currentPhase }).title;
    const dayNumber = daysCountAfterPhaseStartDate + 1;
    const genericMessage = `You are in ${phaseTitle}, and in ${dayNumber} day.`;
    const message = get(
      dailyNotificationMessages,
      `${currentPhase}_${dayNumber}`,
      genericMessage
    );
    swal(message);
  }

  async getHomePageBanners() {
    return ApiService.get("/home-page-banners").then((banners) => {
      store.dispatch(setHomePageBanner(banners));
    });
  }

  async getCycleAlignDescription() {
    return ApiService.get("/cycle-align-descriptions").then((description) => {
      store.dispatch(setCycleAlignDescription(description));
    });
  }

  async setFAQCategories() {
    return ApiService.get("/app-faq-categories").then((categories) => {
      store.dispatch(setFaqCategories(categories));
    });
  }

  async setFastingBanner() {
    return ApiService.get("/page-fastings").then((categories) => {
      store.dispatch(setFastingBannerInfo(categories));
    });
  }

  async getSymptoms() {
    return ApiService.get("/app-symptom-checkers").then((symptoms) => {
      store.dispatch(setSymptomsCheckerList(symptoms));
    });
  }
  async getHomePageIntroduction() {
    return ApiService.get("/home-page-introductions").then((homeIntro) => {
      store.dispatch(setHomeIntroductionsList(homeIntro));
    });
  }
}

export default new HomeService();
