import apiService from "./ApiService";
import { loggedInUserSelector } from "../store/selectors";
import { dispatch, getState } from "../store/store";
import { setReminderPreference } from "../store/appStore/reminderPreference";

class ReminderPreferenceService {
  async getReminderPreference() {
    const loggedInuserDetail = loggedInUserSelector(getState());
    const [usersReminder] = await apiService.get(
      "/users-notification-preferences",
      {
        params: { "user.id": loggedInuserDetail.id },
      }
    );
    dispatch(setReminderPreference(usersReminder));
  }
  async updateReminderPreferenceService(formValues) {
    const { id } = formValues;
    await apiService.put(`/users-notification-preferences/${id}`, {
      ...formValues,
    });
  }
}

export default new ReminderPreferenceService();
