import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useClearCache } from "react-clear-cache";
import browser from "browser-detect";
import { isNil } from "lodash";
import { connect } from "react-redux";
import HeaderSubMenuComponent from "./HeaderSubMenuComponent";
import {
  loggedInUserSelector,
  themeColorSelector,
  unReadNotificationsCountSelector,
} from "../../../store/selectors";
import style from "./HeaderComponent.module.scss";
import MainMenuComponent from "../MainMenuComponent/MainMenuComponent";
import { getTitleFromURL } from "../../../utils";
import HeaderNotificationComponent from "./HeaderNotificationComponent";
import { setUnReadNotificationsCount } from "../../../store/appStore/auth";
import AuthService from "../../../services/AuthService";

function HeaderComponent({
  routeMeta,
  themeColor,
  user,
  setUnReadNotificationsCount,
  unReadNotificationsCount,
}) {
  const [showA2HSInfo, setShowA2HSInfo] = useState(false);
  let history = useHistory();
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const browserObj = browser();

  useEffect(() => {
    // variable store event
    window.deferredPrompt = {};

    // get button with id
    const install_button = document.querySelector("#install");

    // if the app can be installed emit beforeinstallprompt
    window.addEventListener("beforeinstallprompt", (e) => {
      // this event does not fire if the application is already installed
      // then your button still hidden ;)

      // show button with display:block;
      install_button.style.display = "block";

      // prevent default event
      e.preventDefault();

      // store install avaliable event
      window.deferredPrompt = e;

      // wait for click install button by user
      install_button.addEventListener("click", (e) => {
        window.deferredPrompt.prompt();
        window.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            // user accept the prompt

            // lets hidden button
            install_button.style.display = "none";
          } else {
            console.log("User dismissed the prompt");
          }
          window.deferredPrompt = null;
        });
      });
    });

    // if are standalone android OR safari
    if (
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone === true
    ) {
      // hidden the button
      install_button.style.display = "none";
    }

    // do action when finished install
    window.addEventListener("appinstalled", (e) => {
      console.log("success app install!");
    });
  }, []);

  const fetchNoticationCount = useCallback(async () => {
    if (user && user.id) {
      const unReadNotificationsCountResult =
        await AuthService.fetchNoticationCountForState({
          params: { hasRead: false, "user.id": user.id },
        });
      setUnReadNotificationsCount(unReadNotificationsCountResult);
    }
  }, [setUnReadNotificationsCount, user]);

  useEffect(() => {
    fetchNoticationCount();
  }, [fetchNoticationCount]);

  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showMainMenu, setShowMainMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const onSubMenuClick = useCallback(() => {
    setShowSubMenu(!showSubMenu);
  }, [showSubMenu]);

  const onMainMenuClick = useCallback(() => {
    setShowMainMenu(!showMainMenu);
  }, [showMainMenu]);

  const onNotificationClick = useCallback(() => {
    setShowNotification(!showNotification);
  }, [showNotification]);

  const onInstallClick = useCallback(() => {
    const { name } = browserObj;
    if (name !== "chrome") {
      setShowA2HSInfo(true);
    }
  }, [browserObj]);

  return (
    <>
      <div className="newVersionToast">
        {!isLatestVersion && (
          <button
            type="button"
            className="badge badge-pill badge-success px-3 py-2"
            onClick={(e) => {
              e.preventDefault();
              emptyCacheStorage();
            }}
          >
            Update to newer version
          </button>
        )}
      </div>
      <header
        className={`navbar navbar-expand-lg navbar-light fixed-top sticky-top ${
          !themeColor ? "bg-white" : ""
        } header ${style.headerShadow}`}
        style={{ backgroundColor: themeColor }}
      >
        {!isNil(routeMeta) && !isNil(routeMeta.heading) ? (
          <>
            <div className="navbar-brand mr-0 mr-md-2 d-flex align-items-center">
              <i
                className="far fa-long-arrow-left pointer"
                onClick={() => history.goBack()}
              ></i>{" "}
              <span className="font-size-xsmall ml-2">
                {!!routeMeta.heading ? routeMeta.heading : getTitleFromURL()}
              </span>
            </div>
          </>
        ) : (
          <div
            id="mainmenu"
            className={`navbar-brand mr-0 mr-md-2 ${style.pointer}`}
            onClick={onSubMenuClick}
          >
            <span className="navbar-toggler-icon"></span>
          </div>
        )}
        <ul className="navbar-nav flex-row ml-md-auto d-md-flex mr-0">
          <li className="nav-item">
            <div
              id="install"
              onClick={onInstallClick}
              className={`nav-link position-relative ${style.pointer} ${style.notifacationBell}`}
            >
              <i className="fa fa-plus-square"></i>
            </div>
          </li>
          <li className="nav-item">
            <div
              className={`nav-link position-relative ${style.pointer} ${style.notifacationBell}`}
              onClick={onNotificationClick}
            >
              <i className="far fa-bell"></i>
              {unReadNotificationsCount ? (
                <span>{unReadNotificationsCount}</span>
              ) : (
                ""
              )}
            </div>
          </li>
          <li className="nav-item dropdown ellipsis-dropdownMenu">
            {/* <div
              className={`nav-link ${style.pointer}`}
              onClick={onSubMenuClick}
            >
              <i className="far fa-ellipsis-v"></i>
            </div> */}
            {showSubMenu && (
              <HeaderSubMenuComponent
                onSubMenuClick={onSubMenuClick}
                styles={style}
              />
            )}
          </li>
        </ul>
        {showSubMenu && (
          <div onClick={onSubMenuClick} className={style.overlay}></div>
        )}
      </header>
      {showMainMenu && <MainMenuComponent onMenuClose={onMainMenuClick} />}
      {showNotification && (
        <HeaderNotificationComponent
          user={user}
          onMenuClose={onNotificationClick}
          setUnReadNotificationsCount={setUnReadNotificationsCount}
        />
      )}

      {showA2HSInfo && (
        <div className="addToHomeScreenWrapper d-flex justify-content-center align-items-center">
          <div className="d-flex flex-column p-3 info">
            <h4>Add Inaari Shortcut</h4>
            <p className="m-0">
              Click <i className="mx-2 fa fa-ellipsis-v" aria-hidden="true"></i>
              then 'Add to Home Screen' from browser menu to add Inaari shortcut
              for easy access.
            </p>
            <div className="mt-3">
              <button onClick={() => setShowA2HSInfo(false)}>GOT IT!</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default connect(
  (state) => ({
    themeColor: themeColorSelector(state),
    user: loggedInUserSelector(state),
    unReadNotificationsCount: unReadNotificationsCountSelector(state),
  }),
  (dispatch) => ({
    setUnReadNotificationsCount: (unreadCount) =>
      dispatch(setUnReadNotificationsCount(unreadCount)),
  })
)(HeaderComponent);
