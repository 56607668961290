import { isNil } from "lodash";
import moment from "moment";
import ApiService from "./ApiService";
import { batchDispatch, dispatch, getState, store } from "../store/store";
import { getPeriodDetails, loggedInUserSelector } from "../store/selectors";
import { setFastingList } from "../store/appStore/fasting";
import { isEndDateGreaterThanTodayDate } from "../utils";
import {
  setActiveFastConfig,
  setAtiveFastid,
  setFastEndTime,
  setFastHistoryConfig,
  setFastStartTime,
  setFastState,
  setScheduledFast,
} from "../store/appStore/fastConfig";
import { SIMILAR_PERIOD_TYPE } from "../constants";

class FastingService {
  async fetchAllFasting() {
    const periodDetails = getPeriodDetails(getState());
    const {
      phaseModel: { id },
      periodType,
    } = periodDetails;

    return ApiService.get(
      `/fasting-mappings?periodType=${SIMILAR_PERIOD_TYPE[periodType]}&period_phase=${id}`
    ).then((fastingList) => {
      store.dispatch(setFastingList(fastingList));
      return fastingList;
    });
  }

  async getActiveFastConfig() {
    const { id } = loggedInUserSelector(getState());
    return ApiService.get(`/users-fasting-logs?isActive=true&user=${id}`).then(
      (response) => {
        //update store hree
        if (!isNil(response) && !!response.length) {
          const { id, startDateTime, endDateTime } = response[0];
          const isValidEndDate = isEndDateGreaterThanTodayDate(endDateTime);

          if (isValidEndDate) {
            batchDispatch([
              setAtiveFastid(id),
              setActiveFastConfig(response[0]),
              setFastStartTime(startDateTime),
              setFastEndTime(endDateTime),
              setFastState(true),
            ]);
          } else {
            !isNil(id) && this.stopFasting(id, { isActive: false });
            dispatch(setFastState(false));
          }
        }
      }
    );
  }

  async getFutureFastConfig() {
    const { id } = loggedInUserSelector(getState());
    return ApiService.get(`/users-fasting-logs?isActive=false&user=${id}`).then(
      (response) => {
        //update store hree
        if (!isNil(response) && !!response.length) {
          const scheduledFast = response.filter((item) =>
            moment(item.startDateTime).isAfter()
          );
          const sortByLastUpdated = scheduledFast
            .sort((a, b) => a.updated_at.localeCompare(b.updated_at))
            .reverse();
          dispatch(
            setScheduledFast(
              sortByLastUpdated.length > 0 ? [sortByLastUpdated[0]] : []
            )
          );
        }
      }
    );
  }

  async getFastLogHistory() {
    const { id } = loggedInUserSelector(getState());
    return ApiService.get(
      `/users-fasting-logs?isActive=false&user=${id}&_sort=id:desc`
    );
  }

  async createNewFastingLog(config) {
    return ApiService.post("/users-fasting-logs", config).then((response) => {
      dispatch(setActiveFastConfig(response));
    });
  }

  async stopFasting(fastingid, config) {
    await ApiService.put(`/users-fasting-logs/${fastingid}`, config);
    await this.getFastingHistory();
  }

  async onDeleteFasting(fastingid) {
    await ApiService.delete(`/users-fasting-logs/${fastingid}`);
  }

  async updateFutureFast(fastingid, config) {
    await ApiService.put(`/users-fasting-logs/${fastingid}`, config);
  }

  async getFastingHistory() {
    const fastHistory = await ApiService.get(
      "/users-fasting-logs/get-calculated-logs"
    );
    dispatch(setFastHistoryConfig(fastHistory));
  }
}

export default new FastingService();
