import moment from "moment";
import { authTokenSelector } from "../store/selectors";
import { store } from "../store/store";
import config from "../config";
import { isNil, sortBy, startCase } from "lodash";
import { addDays, intervalToDuration } from "date-fns";

export const getIsLoggedIn = () => {
  const token = localStorage.getItem("token");
  const storeToken = authTokenSelector(store.getState());
  if (token !== null && storeToken !== null) {
    return token === storeToken;
  } else {
    return false;
  }
};

export const formatDate = (date, dateFormat) => {
  return moment(date).format(dateFormat);
};

export const removeSpaceAndCase = (str) => {
  return !!str
    ? str
        .trim()
        .replace(/[^a-zA-Z0-9]/g, "-")
        .toLowerCase()
    : "not-found";
};

export function getPercentage(number, total) {
  return (number / total) * 100;
}

export function getAssetUrl(asset) {
  return asset?.url ? `${config.apiBaseUrl}${asset?.url}` : "";
}

export function dateToFromNowDaily(date) {
  // get from-now for this date
  const fromNow = moment(date).fromNow();

  // ensure the date is displayed with today and yesterday
  return moment(date).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: "[Last] dddd",
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    // when the date is further away, use from-now functionality
    sameElse: function () {
      return "[" + fromNow + "]";
    },
  });
}

export function updateFastDurations(fastStartTime, fastEndTime) {
  const startDate = moment(fastStartTime); //now
  const endDate = moment(fastEndTime);
  const totalDuration = endDate.diff(startDate, "minutes") || 1;
  let completedDuration =
    (moment().diff(startDate, "minutes") / totalDuration) * 100;

  if (completedDuration > 100) {
    completedDuration = 100;
  }

  return { totalDuration, completedDuration };
}

const addZero = (number) => {
  return number.toString().length === 1 ? `0${number}` : number;
};

export function remainingTimeFromTwoDays(
  fromDate,
  toDate,
  hideSeconds = false
) {
  const { days, hours, minutes, seconds } = intervalToDuration({
    start: new Date(fromDate),
    end: new Date(toDate),
  });
  const calculatedHours = days * 24 + hours;
  return `${addZero(calculatedHours)}:${addZero(minutes)}${
    hideSeconds ? "" : ":" + addZero(seconds)
  }`;
}

export function getHoursBetweenTwoDays(fromDate, toDate) {
  const { days, hours } = intervalToDuration({
    start: new Date(fromDate),
    end: new Date(toDate),
  });
  const calculatedHours = days * 24 + hours;
  return calculatedHours;
}

export function fastConfigData(
  fastStartTime,
  fastEndTime,
  phaseDuration = null,
  isActive,
  { fasting_mapping_id, userid, periodCycle_id, currrentPhaseId }
) {
  let endTime;
  if (!isNil(phaseDuration) && isNil(fastEndTime)) {
    const duration = phaseDuration.split(":");
    endTime = moment()
      .add({ hours: duration[0], minutes: duration[1] })
      .toDate();
  } else {
    endTime = fastEndTime;
  }

  const { totalDuration } = updateFastDurations(fastStartTime, endTime);

  return {
    startDateTime: fastStartTime,
    endDateTime: endTime,
    fasting_mapping: fasting_mapping_id,
    user: userid,
    period_cycle: periodCycle_id,
    period_phase: currrentPhaseId,
    fastingDuration: totalDuration,
    isActive: isActive,
  };
}

export function isEndDateGreaterThanTodayDate(date) {
  const endDate = new Date(date);
  const todayDate = new Date();
  return endDate >= todayDate ? true : false;
}

export function convertTitleToUrl(title) {
  return (
    !!title &&
    title
      .trim()
      .replace(/[^a-zA-Z0-9]/g, "-")
      .toLowerCase()
  );
}

export function getTitleFromURL() {
  let path = window.location.pathname;
  let lastUrlPart = path.split("/").pop();
  return !!lastUrlPart && startCase(lastUrlPart.replace(/[^a-zA-Z ]/g, " "));
}

export function removeHypenAndConvertToStartCase(str) {
  return str && startCase(str.split("-").join(" "));
}

export function convertTagforApiParams(tags) {
  let tagParams = "";
  if (!!tags.length) {
    const tagItems = tags.map((item) => item.tag);
    tagParams = tagItems.join();
  }
  return tagParams;
}

export function getParameterByName(name, url = window.location.href) {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function enumerateDaysBetweenDates(
  startDate,
  endDate,
  customFormat = null
) {
  let date = [];
  while (moment(startDate) <= moment(endDate)) {
    const dateFormat = !!customFormat
      ? moment(startDate).format(customFormat)
      : startDate;
    date.push(dateFormat);
    startDate = addDays(startDate, 1);
  }
  return date;
}

export function getDates(startDate, stopDate) {
  const dateArray = [];
  let currentDate = moment(startDate);
  const stopTimerDate = moment(stopDate);
  while (currentDate <= stopTimerDate) {
    dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
    currentDate = moment(currentDate).add(1, "days");
  }
  return dateArray;
}

const phaseTypeSortOrder = [
  "Menstruation Phase",
  "Follicular Phase",
  "Ovulation Phase",
  "Luteal Phase",
];

export const customSortedPhaseType = (array) => {
  let sortedArr = [];
  if (!!array.length) {
    sortedArr = sortBy(array, (elem) => phaseTypeSortOrder.indexOf(elem.title));
  }
  return sortedArr;
};

export function get_relative_dates(dates) {
  var format = "YYYY-M-D";
  var newDate = [];
  dates.forEach(function (date) {
    var lastArr, lastDate;
    if (newDate.length) {
      lastArr = newDate[newDate.length - 1];
      if (!lastArr.length) lastArr.push(date);
      else {
        lastDate = lastArr[lastArr.length - 1];
        if (moment(lastDate, format).add(1, "d").format(format) === date)
          lastArr.push(date);
        else newDate.push([date]);
      }
    } else newDate.push([date]);
  });
  return newDate;
}

export function removeFeedingWindow(duration) {
  return `${duration.split(":").shift()}:00`;
}

export function add3Dots(string, limit) {
  const dots = "...";
  if (!!string && string.length > limit) {
    // you can also use substr instead of substring
    string = string.substring(0, limit) + dots;
  }

  return string;
}

export function validateDateBetween(startDate, endDate) {
  const endDateTime = new Date(endDate); //dd-mm-YYYY
  const startTime = new Date(startDate);
  startTime.setSeconds(0);
  endDateTime.setSeconds(0);
  return endDateTime > startTime;
}
