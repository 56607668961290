import createStore from "../createStore";

const initialState = {
  periodDetails: {},
  periodCyclesWithPrediction: {},
};

const [{ setPeriodDetails, setPeriodCyclesWithPrediction }, reducer] =
  createStore("Inaari/period", {
    _initialState: initialState,

    setPeriodDetails: (periodDetails) => (state) => ({
      ...state,
      periodDetails,
    }),
    setPeriodCyclesWithPrediction: (periodCyclesWithPrediction) => (state) => ({
      ...state,
      periodCyclesWithPrediction,
    }),
  });

export { setPeriodDetails, setPeriodCyclesWithPrediction };
export default reducer;
