import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import config from "../../config";
import useQuery from "../../hooks/useQuery";
import AuthService from "../../services/AuthService";

export default function SocialLoginPage() {
  const { provider } = useParams();
  const query = useQuery();
  const redirect = query.get("redirect");
  const onErrorRedirectTo = query.get("onErrorRedirectTo");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && redirect) {
      AuthService.redirectBack(redirect, token);
    } else if (redirect && provider && onErrorRedirectTo) {
      localStorage.setItem("redirect", redirect);
      localStorage.setItem("onErrorRedirectTo", onErrorRedirectTo);
      const googleLoginUrl = `${config.apiBaseUrl}${config.socialLoginApiUrl}/${provider}`;
      window.location.replace(googleLoginUrl);
    }
  }, [onErrorRedirectTo, provider, query, redirect]);

  return <div>Loging In...</div>;
}
