import { addHours } from "date-fns";
import moment from "moment";
import createStore from "../createStore";

let defaultTime = moment(new Date(new Date())).set({
  hour: 21,
  minute: 0,
  seconds: 0,
});
if (defaultTime.isBefore()) {
  defaultTime = moment()
    .add(1, "days")
    .set({ hour: 21, minute: 0, seconds: 0 });
}

const initialState = {
  startTime: new Date(),
  endTime: addHours(new Date(), 12),
  defaultTime,
  activeFastId: null,
  isActiveFast: false,
  isCustomChangedDate: false,
  fastDetails: {},
  scheduledFast: [],
  fastDuration: 0,
  fastCompletedDuration: 0,
  fastCompletedTime: null,
  activeFastConfig: {},
  fastHistoryConfig: {},
};

const [
  {
    setFastStartTime,
    setFastEndTime,
    setDefaultInitialTime,
    setAtiveFastid,
    setFastState,
    setFastCustomeState,
    setFastConfig,
    setScheduledFast,
    setFastDuration,
    setFastCompletedDuration,
    setFastCompletedTime,
    setActiveFastConfig,
    setFastHistoryConfig,
    resetFastConfig,
  },
  reducer,
] = createStore("Inaari/fastConfig", {
  _initialState: initialState,

  setAtiveFastid: (activeFastId) => (state) => ({
    ...state,
    activeFastId,
  }),
  setFastStartTime: (startTime) => (state) => ({
    ...state,
    startTime,
  }),
  setFastEndTime: (endTime) => (state) => ({
    ...state,
    endTime,
  }),
  setDefaultInitialTime: (defaultTime) => (state) => ({
    ...state,
    defaultTime,
  }),
  setFastState: (isActiveFast) => (state) => ({
    ...state,
    isActiveFast,
  }),
  setFastConfig: (fastDetails) => (state) => ({
    ...state,
    fastDetails,
  }),
  setFastDuration: (fastDuration) => (state) => ({
    ...state,
    fastDuration,
  }),
  setFastCompletedDuration: (fastCompletedDuration) => (state) => ({
    ...state,
    fastCompletedDuration,
  }),
  setFastCompletedTime: (fastCompletedTime) => (state) => ({
    ...state,
    fastCompletedTime,
  }),
  setActiveFastConfig: (activeFastConfig) => (state) => ({
    ...state,
    activeFastConfig,
  }),
  setFastHistoryConfig: (fastHistoryConfig) => (state) => ({
    ...state,
    fastHistoryConfig,
  }),
  setScheduledFast: (scheduledFast) => (state) => ({
    ...state,
    scheduledFast,
  }),
  setFastCustomeState: (isCustomChangedDate) => (state) => ({
    ...state,
    isCustomChangedDate,
  }),
  resetFastConfig: () => () => initialState,
});

export {
  setFastStartTime,
  setFastEndTime,
  setDefaultInitialTime,
  setAtiveFastid,
  setFastState,
  setFastConfig,
  setFastDuration,
  setScheduledFast,
  setFastCompletedDuration,
  setFastCompletedTime,
  setActiveFastConfig,
  setFastHistoryConfig,
  setFastCustomeState,
  resetFastConfig,
};
export default reducer;
