import { lazy } from "react";
import LoaderSpinner from "../components/core/LoaderSpinner";
import ErrorPage from "../components/pages/ErrorPage";
import SetRedirectUrlPage from "../components/pages/SetRedirectUrlPage";
import SocialLoginPage from "../components/pages/SocialLoginPage";
import AuthService from "../services/AuthService";
import { authTokenSelector } from "../store/selectors";
import { getState } from "../store/store";

const GetStartedPage = lazy(() => import("../components/pages/GetStartedPage"));
const LoginPage = lazy(() => import("../components/pages/LoginPage"));
const RegisterPage = lazy(() => import("../components/pages/RegisterPage"));
const ForgetPasswordPage = lazy(() =>
  import("../components/pages/ForgetPasswordPage")
);
const AgreementPage = lazy(() => import("../components/pages/AgreementPage"));
const CycleInfoPage = lazy(() => import("../components/pages/CycleInfoPage"));
const HomePage = lazy(() => import("../components/pages/HomePage"));
const WeightLossPage = lazy(() => import("../components/pages/WeightLossPage"));
const DietPlanPage = lazy(() => import("../components/pages/DietPlanPage"));
const FaqPage = lazy(() => import("../components/pages/FaqPage"));
const SocialLoginCallbackPage = lazy(() =>
  import("../components/pages/SocialLoginCallbackPage")
);
const ResetPasswordComponent = lazy(() =>
  import("../components/app/ResetPasswordComponent/ResetPasswordComponent")
);
const StartFastPage = lazy(() => import("../components/pages/StartFastPage"));
const TrackCyclePage = lazy(() => import("../components/pages/TrackCyclePage"));
const EditPeriodDatePage = lazy(() =>
  import("../components/pages/EditPeriodDatePage")
);
const CycleHistoryPage = lazy(() =>
  import("../components/pages/CycleHistoryPage")
);
const MenstruationPage = lazy(() =>
  import("../components/pages/MenstruationPage")
);
const FoodDetailsPage = lazy(() =>
  import("../components/pages/FoodDetailsPage")
);
const FoodPrinciplePage = lazy(() =>
  import("../components/pages/FoodPrinciplePage")
);
const CyclePhasesPage = lazy(() =>
  import("../components/pages/CyclePhasesPage")
);
const LogoutPage = lazy(() => import("../components/pages/LogoutPage"));
const ProfilePage = lazy(() => import("../components/pages/ProfilePage"));
const UpgradePremiumPage = lazy(() =>
  import("../components/pages/UpgradePremiumPage")
);
const AccountSettingsPage = lazy(() =>
  import("../components/pages/AccountSettingsPage")
);
const RemindersPage = lazy(() => import("../components/pages/RemindersPage"));
const TermsOfUsePage = lazy(() => import("../components/pages/TermsOfUsePage"));
const PrivacyPolicyPage = lazy(() =>
  import("../components/pages/PrivacyPolicyPage")
);
const PublicationsPage = lazy(() =>
  import("../components/pages/PublicationsPage")
);
const SupportPage = lazy(() => import("../components/pages/SupportPage"));
const BlogPage = lazy(() => import("../components/pages/BlogPage"));
const CycleSymptomPage = lazy(() =>
  import("../components/pages/CycleSymptomPage")
);
const ChooseFastComponent = lazy(() =>
  import("../components/app/ChooseFastComponent/ChooseFastComponent")
);
const CommunityPage = lazy(() => import("../components/pages/CommunityPage"));
const HealthProfilePage = lazy(() =>
  import("../components/pages/HealthProfilePage")
);
const TribeTopicPage = lazy(() => import("../components/pages/TribeTopicPage"));
const HomeBannerDetailPage = lazy(() =>
  import("../components/pages/HomeBannerDetailPage")
);
const BlogInnerPage = lazy(() => import("../components/pages/BlogInnerPage"));
const WhatsHappeningPage = lazy(() =>
  import("../components/pages/WhatsHappeningPage")
);

const CompleteProfilePage = lazy(() =>
  import("../components/pages/CompleteProfilePage")
);
const ReminderSingleComponent = lazy(() =>
  import("../components/app/RemindersComponent/ReminderSingleComponent")
);
const BlogGridComponent = lazy(() =>
  import("../components/app/BlogComponent/BlogGridComponent")
);
const WeightLossRecommededDiet = lazy(() =>
  import("../components/app/WeightLossComponent/WeightLossRecommededDiet")
);
const AcademyPage = lazy(() => import("../components/pages/AcademyPage"));

const isLoggedIn = authTokenSelector(getState());

export default () => [
  {
    path: "/",
    exact: true,
    component: GetStartedPage,
    meta: { isAuth: false },
  },
  {
    path: "/get-started",
    exact: true,
    component: GetStartedPage,
    meta: { isAuth: false },
  },
  {
    path: "/login",
    exact: true,
    component: LoginPage,
    meta: {},
  },
  {
    path: "/register",
    exact: true,
    component: RegisterPage,
    meta: { isAuth: false },
  },
  {
    path: "/connect/:provider/redirect",
    component: SocialLoginCallbackPage,
    meta: { isAuth: false },
  },
  {
    path: "/connect/:provider/login",
    component: SocialLoginPage,
    meta: {},
  },
  {
    path: "/cycle-info",
    exact: true,
    component: CycleInfoPage,
    meta: { isAuth: true, showHeader: true },
  },
  {
    path: "/complete-profile",
    exact: true,
    component: CompleteProfilePage,
    meta: { isAuth: true, showHeader: false },
  },
  {
    path: "/forgot-password",
    exact: true,
    component: ForgetPasswordPage,
    meta: { isAuth: false },
  },
  {
    path: "/reset-password",
    exact: true,
    component: ResetPasswordComponent,
    meta: { isAuth: false },
  },
  {
    path: "/agreement",
    exact: true,
    component: AgreementPage,
    meta: { isAuth: false },
  },
  {
    path: "/logout",
    exact: true,
    meta: { showHeader: false },
    component: LogoutPage,
  },
  {
    path: "/set-redirect-url",
    exact: true,
    meta: { showHeader: false },
    component: SetRedirectUrlPage,
  },
  {
    path: "/home",
    exact: true,
    meta: { isAuth: true, showHeader: true },
    component: HomePage,
  },
  {
    path: "/banner-details/:id/:name",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "" },
    component: HomeBannerDetailPage,
  },
  {
    path: "/food-article/:id/:id",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Blog" },
    component: BlogInnerPage,
  },
  {
    path: "/blog-article/:blogId/:blogTitle",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Blog" },
    component: BlogInnerPage,
  },
  {
    path: "/blog-category/:blogCategory",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Blog" },
    component: BlogGridComponent,
  },
  {
    path: "/weight-loss",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Weight Loss" },
    component: WeightLossPage,
  },
  {
    path: "/diet-plan",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Diet Plan" },
    component: DietPlanPage,
  },
  {
    path: "/weight-loss-diet-plan",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Diet Plan" },
    component: WeightLossRecommededDiet,
  },
  {
    path: "/diet-plan/:api/:currentPeriod/:dietCode",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Diet Plan" },
    component: DietPlanPage,
  },
  {
    path: "/faq",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "FAQs" },
    component: FaqPage,
  },
  {
    path: "/start-fasting",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Start Fasting" },
    component: StartFastPage,
  },
  {
    path: "/track-cycle",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Track Cycle" },
    component: TrackCyclePage,
  },
  {
    path: "/edit-period-dates",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Edit Period Date" },
    component: EditPeriodDatePage,
  },
  {
    path: "/cycle-history",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Cycle History" },
    component: CycleHistoryPage,
  },
  {
    path: "/phase-information",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: AuthService.getPhase() },
    component: MenstruationPage,
  },
  {
    path: "/food-details/:id",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Foods To Eat" },
    component: FoodDetailsPage,
  },
  {
    path: "/food-principle/:id",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Foods Principle" },
    component: FoodPrinciplePage,
  },
  {
    path: "/cycle-phases/:id",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Cycle Phases" },
    component: CyclePhasesPage,
  },
  {
    path: "/profile",
    exact: true,
    meta: {
      isAuth: true,
      showHeader: true,
      heading: "Profile",
      globalClassName: "bg-profile-header",
    },
    component: ProfilePage,
  },
  {
    path: "/upgrade-premium",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Upgrade to Premium" },
    component: UpgradePremiumPage,
  },
  {
    path: "/account-settings",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Account Settings" },
    component: AccountSettingsPage,
  },
  {
    path: "/reminders",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Reminders" },
    component: RemindersPage,
  },
  {
    path: "/reminders/:parentKeyName/:childKeyName",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Reminders" },
    component: ReminderSingleComponent,
  },
  {
    path: "/terms-of-use",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Terms of Use" },
    component: TermsOfUsePage,
  },
  {
    path: "/privacy-policy",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Privacy Policy" },
    component: PrivacyPolicyPage,
  },
  {
    path: "/publications",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Publications" },
    component: PublicationsPage,
  },
  {
    path: "/support",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Support" },
    component: SupportPage,
  },
  {
    path: "/blog",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Blog" },
    component: BlogPage,
  },
  {
    path: "/symptoms",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Symptoms & Flow Log" },
    component: CycleSymptomPage,
  },
  {
    path: "/choose-fast",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Choose Fast" },
    component: ChooseFastComponent,
  },
  {
    path: "/community",
    exact: true,
    meta: { isAuth: true, showHeader: true },
    component: CommunityPage,
  },
  {
    path: "/health-profile",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Health Profile" },
    component: HealthProfilePage,
  },
  {
    path: "/tribe-topic/:topicId",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Sisterhood" },
    component: TribeTopicPage,
  },
  {
    path: "/whats-happening",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "What’s Happening?" },
    component: WhatsHappeningPage,
  },
  {
    path: "/academy",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Academy" },
    component: AcademyPage,
  },
  {
    path: "/academy/:categoryName",
    exact: true,
    meta: { isAuth: true, showHeader: true, heading: "Academy" },
    component: AcademyPage,
  },
  {
    path: "/admin",
    exact: true,
    meta: { isAuth: false, redirect: true, url: "https://inaari.org/admin/" },
    component: LoaderSpinner,
  },
  {
    path: "*",
    exact: true,
    meta: { isAuth: isLoggedIn, showHeader: isLoggedIn, heading: "Error" },
    component: ErrorPage,
  },
];
