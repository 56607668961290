import createStore from "../createStore";

const initialState = {
  token: "",
  user: {},
  tribeToken: "",
  fcmToken: "",
  unReadNotificationsCount: 0,
};

const [
  {
    setAuth,
    setUser,
    resetAuth,
    setTribeToken,
    setFCMToken,
    setUnReadNotificationsCount,
  },
  reducer,
] = createStore("Inaari/auth", {
  _initialState: initialState,

  setAuth: (auth) => (state) => ({
    ...state,
    token: auth.token,
    user: auth.user,
  }),
  setUser: (user) => (state) => ({
    ...state,
    user,
  }),
  resetAuth: () => () => initialState,
  setUnReadNotificationsCount: (count) => (state) => ({
    ...state,
    unReadNotificationsCount: count,
  }),

  setTribeToken: (tribeToken) => (state) => ({
    ...state,
    tribeToken,
  }),
  setFCMToken: (fcmToken) => (state) => ({
    ...state,
    fcmToken,
  }),
});

export {
  setAuth,
  setUser,
  resetAuth,
  setTribeToken,
  setFCMToken,
  setUnReadNotificationsCount,
};
export default reducer;
