import createStore from "../createStore";

const initialState = {
  banners: [],
  cycleAlignDescription: [],
  symptomsCheckerList: [],
  homeIntroductionsList: [],
};

const [
  {
    setHomePageBanner,
    setCycleAlignDescription,
    setSymptomsCheckerList,
    setHomeIntroductionsList,
  },
  reducer,
] = createStore("Inaari/home", {
  _initialState: initialState,

  setHomePageBanner: (banners) => (state) => ({
    ...state,
    banners,
  }),
  setCycleAlignDescription: (cycleAlignDescription) => (state) => ({
    ...state,
    cycleAlignDescription,
  }),
  setSymptomsCheckerList: (symptomsCheckerList) => (state) => ({
    ...state,
    symptomsCheckerList,
  }),
  setHomeIntroductionsList: (homeIntroductionsList) => (state) => ({
    ...state,
    homeIntroductionsList,
  }),
});

export {
  setHomePageBanner,
  setCycleAlignDescription,
  setSymptomsCheckerList,
  setHomeIntroductionsList,
};
export default reducer;
